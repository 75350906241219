import { defineStore, acceptHMRUpdate } from 'pinia'

export const useConditionStore = defineStore('conditionStore', {
  state: () => {
    return {
      layout: {
        fullscreenPages: ["video-slug", "studio-slug", "app"],
        isShowHeaderBar: false,
      },
      modal: {
        playaInstall: false,
        changeUsername: false,
        deleteAccount: false,
        changeEmail: false,
        setNewPassword: false,
        forgotPassword: false,
        forgotPasswordEmailSend: false,
        forgotPasswordSuccess: false,
        fileUpload: false,
      },
      vrcam: {
        availableOnlyInPrivateShow: false,
        complimentSentError: false,
        complimentSentSuccess: false,
        getFullAccess: false,
        notEnoughTokens: false,
        privateShowDoLeave: false,
        // Your request for private chat has been sent successfully
        privateShowHasBegun: false,
        // Are you sure you want to cancel your request for private show with {model}?
        privateShowInvite: false,
        privateShowIsOver: false,
        privateShowMinutesWarn: false,
        // Unfortunately, Teresa Adler is unable to accept your request for private chat now. You may send a new request later.
        privateShowPay: false,
        privateShowRequestCancel: false,
        // with tips
        privateShowRequestSentSuccess: false,
        privateShowRequestUnableAccept: false,
        spyOnLeave: false,
        spyOnStart: false,
        spyShowMinutesWarn: false,
      },
    }
  },
  getters: {
    isActiveModal(state) {
      const keys = Object.keys(state.modal)
      let isActive = false
      for (const index in keys) {
        if (state.modal[keys[index]]) {
          isActive = true
          break
        }
      }
      return isActive
    },
    activeModals(state) {
      const keys = Object.keys(state.modal)
      const activeModals: string[] = []
      for (const index in keys) {
        if (state.modal[keys[index]]) {
          activeModals.push(keys[index])
        }
      }
      return activeModals
    },
    layoutFullscreenPages(state) {
      return state.layout.fullscreenPages
    },
    layoutIsShowHeaderBar(state) {
      return state.layout.isShowHeaderBar
    }
  },
  actions: {
    // modal
    // modal forgotPassword
    showForgotPassword() {
      this.modal.forgotPassword = true
      this.activeBody(true)
    },
    hideForgotPassword() {
      this.modal.forgotPassword = false
      this.activeBody(false)
    },
    toggleForgotPassword() {
      if (this.modal.forgotPassword) {
        return this.hideForgotPassword()
      }
      return this.showForgotPassword()
    },

    activeBody (active: boolean) {
      if (import.meta.server) {
        return
      }
      document.body.style.overflowY = active ? "hidden" : "auto"
    },

    setIsShowHeaderBar(newValue: boolean) {
      this.layout.isShowHeaderBar = newValue
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConditionStore, import.meta.hot))
}
